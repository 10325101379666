.hero {
	min-height: 100vh;
	width: 100%;
}

.hero .banner.banner-effect {
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	background-size: 100%;
}

.hero .page-content {
	margin-top: 70vh;
	padding-bottom: 10vh;
	/* overflow-x: hidden; */
}

.hero #banner-image-wrapper {
	position: absolute;
	right: 0;
	overflow: hidden;
	width: 0;
}

.hero #banner-image-wrapper .banner-image {
	position: absolute;
	object-fit: cover;
	object-position: center;
	height: 100vh;
	max-height: 100vh;
	max-height: -webkit-fill-available;
	width: 50vw;
	top: 0;
	right: 0;

	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
}

.hero #banner-image-wrapper .banner-image .banner-image-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	max-height: 100%;
	max-height: -webkit-fill-available;
	background-color: transparent;
	z-index: 2;
}

.hero #banner-image-wrapper .banner-image .banner-image-overlay {
	background-color: #00000088;
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.7) 0%,
		rgba(0, 0, 0, 0.2) 25%,
		rgba(0, 0, 0, 0) 100%
	);
}

.hero
	#banner-image-wrapper
	.banner-image
	.banner-image-overlay.banner-image-overlay-white {
	background-color: #c0d4df88;
	background: linear-gradient(
		180deg,
		rgba(192, 212, 223, 0.7) 0%,
		rgba(192, 212, 223, 0.5) 25%,
		rgba(192, 212, 223, 0) 100%
	);
}

@media (max-width: 1023px) {
	.hero #banner-image-wrapper .banner-image {
		width: 100vw;
	}
	.hero #banner-image-wrapper .banner-image .banner-image-overlay {
		background: none;
		background-color: #00000022;
	}
	.hero
		#banner-image-wrapper
		.banner-image
		.banner-image-overlay.banner-image-overlay-white {
		background-color: #c0d4df55;
	}
}

@media (max-width: 850px) {
	.hero {
		width: 100%;
	}

	.hero .banner.banner-effect {
		width: 100% !important;
	}

	.hero .page-content {
		overflow-x: hidden;
	}
}

.hero .banner-wrapper {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	max-height: 100vh;
	max-height: -webkit-fill-available;
}

.hero .banner-wrapper * {
	max-height: 100vh;
	max-height: -webkit-fill-available;
}
