$color-bg: #fff;
$color-text: #fff;
$color-highlight: #fff;

$color-text: #fff;
$color-text-light: #fff;
$color-stroke: #595f69;
$color-stroke-dotted: hsla(0, 0%, 100%, 0.45);
$color-bg: #191919;
$color-highlight: #fff;
$color-highlight-text: #fff;
$color-highlight-complement-text: #fff;
$color-text-over-highlight: #000;
$color-text-over-highlight-hover: #000;
$color-highlight-hover: #a4abb4;
$color-form-bg: #191919;

$height: --webkit-fill-available;

.global-header {
	height: 76px;
	padding-left: 21px;
	padding-right: 21px;
	background-color: transparent;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 100;
	transition: background-color 0.5s cubic-bezier(0.23, 1, 0.32, 1),
		transform 0.5s cubic-bezier(0.23, 1, 0.32, 1),
		filter 0.5s cubic-bezier(0.23, 1, 0.32, 1);

	&.white {
		filter: invert(1);
	}

	@media screen and (min-width: 1024px) {
		padding-left: 77px;
		padding-right: 77px;
		height: 115px;
	}

	&.is-background-active {
		background-color: $color-bg;
		filter: invert(0);
	}

	&.is-active {
		filter: invert(0);
	}

	&.is-hidden {
		transform: translateY(-100%);
	}

	.global-header__mobile-nav-trigger {
		// top: 21px;
		// right: 21px;
		display: block;
		position: fixed;
		width: 3rem;
		height: 3rem;
		background-color: $color-highlight;
		border-radius: 50%;
		color: $color-bg;
	}

	.global-header__mobile-nav-trigger,
	.global-header__mobile-nav-trigger svg {
		width: 3rem;
		height: 3rem;
	}
	@media screen and (min-width: 1024px) {
		.global-header__mobile-nav-trigger {
			// top: calc(21px + 19 * (100vw - 320px) / 704);
			right: calc(21px + 19 * (100vw - 320px) / 704);
		}
	}

	@media (max-width: 1023px) {
		align-items: flex-start;
		padding-top: 23px;
		&.is-active {
			align-items: flex-start;
			height: 100vh;
			max-height: 100vh;
			max-height: -webkit-fill-available;
			padding-top: 23px;
			padding-bottom: 23px;
		}
		.global-header__mobile-nav-trigger {
			// top: calc(21px + 19 * (100vw - 320px) / 704);
			right: calc(21px + 19 * (100vw - 320px) / 704);
		}

		&.is-active .global-header__logo-perm,
		&.is-active .global-header__nav {
			pointer-events: auto;
			opacity: 1;
		}

		.global-header__nav > .global-header__mobile-nav-trigger {
			top: 23px;
			transition: 0.4s cubic-bezier(0.23, 1, 0.32, 1);
			transition-property: opacity, transform;
			transform: rotate(-90deg);
		}

		&.is-active .global-header__nav > .global-header__mobile-nav-trigger {
			opacity: 1;
			transform: rotate(0);
			z-index: 99;
		}

		&.is-active .global-header__item {
			opacity: 1;
		}

		&.is-active .global-header__cta-nav {
			opacity: 1;
			pointer-events: auto;
		}
	}

	@media (min-width: 1024px) {
		.global-header__mobile-nav-trigger {
			display: none;
		}
	}
}

.global-header__logo {
	color: $color-text;
	width: 8.2rem;
	display: block;
	position: relative;
	z-index: 1;
	transition: opacity 3s cubic-bezier(0.23, 1, 0.32, 1);
	transition-delay: 0.4s;
	opacity: 1;

	@media (min-width: 64em) {
		width: 11.4rem;
		margin-right: 2rem;
	}

	&.is-visible {
		opacity: 1;
	}

	.global-header__logo-anim {
		transform-origin: top left;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		transition: opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1);
		&.is-visible {
			opacity: 1;
			pointer-events: auto;
		}
	}

	.global-header__logo-perm {
		width: 100%;
		transition: opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1);
		&.is-visible {
			opacity: 1;
			pointer-events: auto;
		}
	}
}

.logo {
	display: block;
	.logo__inner {
		.logo__innermost {
			position: relative;
			height: 0;
			width: 100%;
			padding: 0 0 32.17%;
			svg {
				position: absolute;
				height: 100%;
				width: 100%;
				left: 0;
				top: 0;
			}
		}
	}
}

.global-header__nav {
	margin-left: auto;
	margin-right: 5rem;

	&:before {
		content: "";
		display: block;
		position: absolute;
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		top: 76px;
		background-color: #fff;
		transition: opacity 0.4s cubic-bezier(0.23, 1, 0.32, 1);
		pointer-events: none;
		opacity: 0;
	}

	.global-header__nav-indicator {
		top: 76px;
		display: block;
		width: 300px;
		height: 3px;
		background-color: $color-highlight;
		position: absolute;
		left: 0;
		margin-top: -3px;
		transition: 0.8s cubic-bezier(0.19, 1, 0.22, 1);
		transition-property: opacity, transform;
		transform-origin: 0 100%;
		transform: translateX(0) scaleX(0) scaleY(0);
		opacity: 0;
		z-index: 1;
	}

	.global-header__item-list {
		top: 76px;
		padding: 56px 56px 61.6px;
		display: flex;
		position: absolute;
		left: 0;
		right: 0;
		background-color: $color-bg;
		transition: background-color 0.5s cubic-bezier(0.23, 1, 0.32, 1);
		color: $color-text;
		z-index: 9999;
		justify-content: space-around;
		pointer-events: none;

		&.is-hidden {
			background-color: transparent;
			height: 0;
			overflow: hidden;
			padding: 0;
			margin: 0;
			z-index: 999;
		}

		@media (min-width: 1024px) {
			&.custom-padding {
				padding-right: 25%;
				padding-left: 25%;
			}
		}

		@media screen and (max-width: 1023px) {
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			top: 76px;
			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			background-color: #191919;
			z-index: 1;
			visibility: hidden;
			transition: 0.4s cubic-bezier(0.23, 1, 0.32, 1);
			transition-property: opacity, transform;
			opacity: 0;
			transform: translateX(20%);

			padding-bottom: calc(126px + 114 * (100vw - 320px) / 704);
			padding-left: calc(21px + 19 * (100vw - 320px) / 704);
			padding-right: calc(21px + 19 * (100vw - 320px) / 704);
			top: calc(76px + 39 * (100vw - 320px) / 704);
			.global-header__nav .global-header__nav-list {
				padding-left: calc(21px + 19 * (100vw - 320px) / 704);
				padding-right: calc(21px + 19 * (100vw - 320px) / 704);
			}
		}
	}

	.global-header__item-list-item {
		transition: 0.8s cubic-bezier(0.19, 1, 0.22, 1);
		// transition-property: opacity, transform;
		opacity: 0;
		transform: translateY(1rem);
		color: $color-text;
		justify-content: center;
		max-width: 25rem;

		@for $i from 1 through 10 {
			&:nth-child(#{$i}n) {
				transition-delay: #{$i * 0.05}s;
			}
		}

		& + .global-header__item-list-item {
			margin-left: 30px;

			@media (min-width: 1024px) {
				margin-left: calc(30px + 60 * (100vw - 1024px) / 416);
			}

			@media (min-width: 1440px) {
				margin-left: 90px;
			}

			@media (max-width: 1023px) {
				margin-left: 00px;
			}
		}

		@media (max-width: 1023px) {
			.link .link__description {
				display: none;
			}
		}
	}

	@media (min-width: 1024px) {
		top: 115px;
		&:before {
			top: 115px;
		}
		&.is-active:before {
			opacity: 1;
			pointer-events: auto;
		}
		.global-header__nav-indicator {
			top: 115px;
		}
		.global-header__item-list {
			top: 115px;
		}

		.global-header__item-list-back-bar {
			display: none;
		}
	}

	@media screen and (max-width: 1023px) {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: #191919;
		opacity: 0;
		pointer-events: none;
		top: 0;
		margin-right: 0;
		padding-top: 75px;
		height: 100vh;
		max-height: 100vh;
		max-height: -webkit-fill-available;

		&:before {
			top: calc(76px + 39 * (100vw - 320px) / 704);
		}

		.global-header__nav-indicator {
			top: calc(76px + 39 * (100vw - 320px) / 704);
		}

		.global-header__item:nth-child(1n + 2) {
			margin-top: 4.5rem;
		}

		.global-header__item-list {
			top: 40px;
		}

		.global-header__item-list-back-bar {
			padding-bottom: 40px;
			display: flex;
			justify-content: space-between;

			span {
				width: calc(18px + 12 * (100vw - 320px) / 448);

				svg {
					width: 1.5rem;
					height: 0.8rem;
					transform: rotate(90deg);
					margin-left: -0.4rem;
				}
			}
		}

		.global-header-nav__buttons {
			position: absolute;
			bottom: 0;

			padding-left: calc(21px + 19 * (100vw - 320px) / 704);
			padding-right: calc(21px + 19 * (100vw - 320px) / 704);

			padding-top: calc(27.3px + 24.7 * (100vw - 320px) / 704);
			padding-bottom: calc(27.3px + 24.7 * (100vw - 320px) / 704);
		}
	}

	.global-header__nav-list {
		@media (min-width: 1024px) {
			display: flex;
			justify-content: flex-end;
		}
		@media (max-width: 1023px) {
			padding-left: calc(21px + 19 * (100vw - 320px) / 704);
			padding-right: calc(21px + 19 * (100vw - 320px) / 704);
		}
		list-style-type: none;
		margin-block-start: 1em;
		margin-block-end: 1em;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-inline-start: 40px;
	}
}

.global-header__item {
	margin-top: 0.5rem;
	padding: 0 20px;
	z-index: 999;

	@media (max-width: 1023px) {
		padding: 0;
	}

	&.is-active {
		z-index: 9999;
	}
	.global-header__item-list {
		// visibility: hidden;
		background-color: transparent;
		.global-header__item-list-item {
			opacity: 0;
			pointer-events: none;
		}
	}
	&.is-active .global-header__item-list {
		// visibility: visible;

		pointer-events: initial;
		@media (max-width: 1023px) {
			opacity: 1;
			transform: translateX(0);
			visibility: visible;
			height: 100vh;
		}
		background-color: $color-bg;
		.global-header__item-list-item {
			opacity: 1;
			transform: translateY(0);
			pointer-events: initial;
		}
	}

	&.is-visible .global-header__item-list {
		display: flex;
		@media (max-width: 1023px) {
			display: block;
		}
	}
}

.global-header__link {
	text-decoration: none;
	font-size: 1.6rem;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	// font-family: Lausanne, Helvetica Neue, arial, sans-serif;
	line-height: 1.38;
	font-weight: 500;
	color: $color-text-light;
	transition: 0.4s cubic-bezier(0.23, 1, 0.32, 1);
	transition-property: opacity, color;
	display: flex;
	align-items: center;
	position: relative;
}

.global-header__link:hover {
	opacity: 0.7;
	color: #f3f2ee;
}

.global-header__link > span,
.global-header__link span svg {
	width: 1.5rem;
	height: 0.8rem;
	display: block;
}

.global-header__link > span {
	margin-left: 0.4em;
	margin-top: -0.2rem;

	@media (max-width: 1023px) {
		margin-left: 0.9em;
		margin-top: -0.1rem;
		transform: rotate(-90deg);
	}
}

// @media (max-width: 63.99em)

@media (max-width: 1023px) {
	.global-header__cta-nav {
		transition: 0.4s cubic-bezier(0.23, 1, 0.32, 1);
		transition-delay: 0s;
		transition-property: opacity, transform;
		transform: translateY(1rem);
		opacity: 0;
		pointer-events: none;
		padding: 27.3px 21px;
		position: fixed;
		left: 0;
		top: calc(100vh - 177px);
		width: 100%;
		display: flex;
		justify-content: space-between;
		background-color: #191919;
		max-width: 40rem;
		display: none;
	}
	// @media screen and (max-width: 63.99em) and (min-width: 320px)
	.global-header__cta-nav {
		padding-top: calc(27.3px + 24.7 * (100vw - 320px) / 704);
		padding-bottom: calc(27.3px + 24.7 * (100vw - 320px) / 704);
	}
	// @media screen and (max-width: 63.99em) and (min-width: 320px)
	.global-header__cta-nav {
		padding-left: calc(21px + 19 * (100vw - 320px) / 704);
		padding-right: calc(21px + 19 * (100vw - 320px) / 704);
	}
}
