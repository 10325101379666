.squares-horizontal-scroll {
	display: flex;
	overflow-x: auto;
	margin-top: 5vw;
	min-height: 100%;

	.squares-horizontal-scroll-item {
		min-height: 75vw;
		min-width: 75vw;
		margin-right: 5vw;
		display: inline-block;
		padding: 40px;
		white-space: normal;

		&:last-child {
			margin-right: 0;
		}
		@media screen and (orientation: landscape) {
			min-height: 100%;
			min-width: 0;
			width: 50vw;
			max-width: 70ch;
		}
	}
}
