.right-col {
	margin: 0;
	padding: 0;
	min-height: 70vh;
	max-height: 100%;
	position: relative;
	overflow: hidden;

	.background-image-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;

		.background-image {
			margin: 0;
			padding: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			z-index: 1;
		}

		.background-image-overlay {
			position: absolute;
			margin: 0;
			padding: 0;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #00000022;
			z-index: 2;
		}
	}

	.text-container {
		position: absolute;
		// bottom: -100px;
		bottom: 0;
		// @media (max-width: 1023px) {
		// 	bottom: 0;
		// }
		z-index: 2;
		transition: 0.5s ease;
		margin: 0;
		padding: 0;
		.background-solid {
			height: 0; // calc(100%);
			width: 50vw;
			position: absolute;
			bottom: 0;
			margin: 0;
			padding: 0;
			transition: 0.5s ease;
			z-index: 0;

			@media (max-width: 1023px) {
				height: 0;
			}
		}
		.text-wrapper {
			position: relative;
			max-width: 65ch;
			padding: 50px;
			z-index: 2;
			// .custom-margin {
			// 	margin-bottom: 40px;
			// }

			& > * {
				opacity: 0;
			}

			@media (max-width: 1023px) {
				padding: 30px;
				.custom-margin {
					margin-bottom: 40px;
				}
			}
		}
	}

	&:hover {
		.background-image-wrapper {
		}

		.text-container {
			bottom: 0;
			z-index: 2;

			// .background-solid {
			// 	height: 100%;
			// }
		}
	}
}
