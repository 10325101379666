@font-face {
	font-family: "Whyte Inktrap";
	src: url("./assets/fonts/WhyteInktrap-Light.woff2") format("woff2"),
		url("./assets/fonts/WhyteInktrap-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Whyte Inktrap";
	src: url("./assets/fonts/WhyteInktrap-Regular.woff2") format("woff2"),
		url("./assets/fonts/WhyteInktrap-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

// Override default variables before the import
$body-bg: #000;
$color-highlight: #fff;
$height: 3.1rem;
$color-text-over-highlight: #fff;
$color-stroke-dotted: rgb(213, 213, 213);

$transition-timing-function: cubic-bezier(0.8, 0.225, 0.29, 0.74); /* custom */

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

html {
	font-size: 10px;
	height: -webkit-fill-available;
}

body {
	font-size: 1.8rem;
	min-height: 100vh;
	min-height: -webkit-fill-available;
	.stop-scrolling {
		height: 100%;
		overflow: hidden;
	}
}

a:hover .link {
	color: var(--color-highlight);
}

.link {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
	border: none;
	background-color: initial;
	padding: 0;
	transition: 0.4s cubic-bezier(0.23, 1, 0.32, 1);
	transition-property: color;
	cursor: pointer;
	color: currentColor;
	display: inline-block;
	text-decoration: none;

	@media (max-width: 1023px) {
		margin-bottom: 4rem;
	}

	&:focus,
	&:hover {
		color: $color-highlight;
	}

	.link__text {
		display: block;

		& > span,
		& > span > span {
			background-position: 0 100%;
			display: inline;
			padding-bottom: 0.26em;
		}
		& > span {
			// background-image: linear-gradient(
			// 	90deg,
			// 	$color-stroke-dotted,
			// 	$color-stroke-dotted 2px,
			// 	transparent 0,
			// 	transparent
			// );

			background-image: linear-gradient(
				$color-stroke-dotted,
				$color-stroke-dotted
			);
			background-repeat: repeat-x;
			background-size: 4px 1px;
			transition-property: background-size;

			&.black {
				background-image: linear-gradient(#1c1c1c, #1c1c1c);
			}
		}

		& > span > span {
			background-image: linear-gradient(#e17152, #e17152);
			&.white {
				background-image: linear-gradient(
					$color-stroke-dotted,
					$color-stroke-dotted
				);
			}

			&.orange {
				background-image: linear-gradient(#e17152, #e17152);
			}
			background-repeat: no-repeat;
			background-size: 0 1px;
			transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
			transition-property: background-size;
		}
	}

	.link__description {
		font-size: 1.6rem;
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		font-family: Lausanne, Helvetica Neue, arial, sans-serif;
		line-height: 1.38;
		font-weight: 300;
		display: block;
		margin-top: 1.8rem;
		@media (max-width: 1023px) {
			display: none;
		}
	}
}

.link:focus .link__text > span > span,
.link:hover .link__text > span > span,
a:hover .link .link__text > span > span {
	background-size: 100% 1px;
}

.btn,
.btn__inner {
	position: relative;
}
.btn {
	--height: 3.1rem;
	display: inline-block;
	will-change: transform;
}

.btn__inner {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
	border: none;
	background-color: #fff;
	color: #000;
	display: block;
	z-index: 1;
	padding: 0.1rem 2rem 0;
	height: $height;
	line-height: $height;
	transition: color 0.4s cubic-bezier(0.23, 0.7, 0.21, 1);
	cursor: pointer;
	text-align: center;
	border-radius: calc(#{$height} * 0.5);
	transition-property: background-color, border-color;
	text-decoration: none;
}

.btn--hollow > .btn__inner {
	background-color: transparent;
	color: #fff;
	border: 1px solid #fff;
}

.btn__text {
	font-size: 1.6rem;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	line-height: 1.38;
	font-weight: 300;
}

.btn:hover .btn__inner {
	background-color: grey;
	color: white;
}

.btn.btn--hollow:hover .btn__inner {
	background-color: grey;
	color: black;
}

.transition-custom-easing {
	transition-timing-function: $transition-timing-function;
}

.transition-width {
	transition: "width 0.7s";
}

//BACKGROUND COLORS

.bg-black {
	background-color: #1c1c1c;
}

.bg-green {
	background-color: #78c797;
}

.bg-orange {
	background-color: #e17152;
}

.bg-grey {
	background-color: #f3f3f3;
}

.bg-purple {
	background-color: #786eac;
}

.bg-light-blue {
	background-color: #b2cbd8;
}

.bg-blue {
	background-color: #5b73ab;
}

.bg-yellow {
	background-color: #ffcf31;
}

.bg-pink {
	background-color: #edb4b5;
}
// BG Hovers

.bg-on-hover-black:hover {
	background-color: #1c1c1c;
}

.bg-on-hover-green:hover {
	background-color: #78c797;
}

.bg-on-hover-light-green:hover {
	background-color: #dae2da;
}

.bg-on-hover-orange:hover {
	background-color: #e17152;
}

.bg-on-hover-grey:hover {
	background-color: #f3f3f3;
}

.bg-on-hover-purple:hover {
	background-color: #786eac;
}

.bg-on-hover-light-blue:hover {
	background-color: #b2cbd8;
}

.bg-on-hover-blue:hover {
	background-color: #5b73ab;
}

.bg-on-hover-yellow:hover {
	background-color: #ffcf31;
}

.bg-on-hover-pink:hover {
	background-color: #edb4b5;
}
//BORDERS

.border-black {
	border-color: #1c1c1c !important;
}

.border-green {
	border-color: #78c797;
}

.border-orange {
	border-color: #e17152;
}

.border-grey {
	border-color: #f3f3f3;
}

.border-medium-grey {
	border-color: #c4c4c4;
}

.border-dark-grey {
	border-color: #404040;
}

.border-purple {
	border-color: #786eac;
}

.border-light-blue {
	border-color: #b2cbd8;
}

.border-blue {
	border-color: #5b73ab;
}

.border-yellow {
	border-color: #ffcf31;
}

.border-pink {
	border-color: #edb4b5;
}

// BORDER HOVERS

.border-on-hover-black:hover {
	border-color: #1c1c1c;
}

.border-on-hover-green:hover {
	border-color: #78c797;
}

.border-on-hover-light-green:hover {
	border-color: #dae2da;
}

.border-on-hover-orange:hover {
	border-color: #e17152;
}

.border-on-hover-grey:hover {
	border-color: #f3f3f3;
}

.border-on-hover-purple:hover {
	border-color: #786eac;
}

.border-on-hover-light-blue:hover {
	border-color: #b2cbd8;
}

.border-on-hover-blue:hover {
	border-color: #5b73ab;
}

.border-on-hover-yellow:hover {
	border-color: #ffcf31;
}

.border-on-hover-pink:hover {
	border-color: #edb4b5;
}

//TEXT COLORS

.text-grey {
	color: #5c5c5c;
}

.text-light-grey {
	color: #f3f2ee;
}

.text-white {
	color: #dbdcda;
}

.text-black {
	color: #000000;
}

.text-yellow {
	color: #ffcf31;
}

.text-orange {
	color: #e17152;
}

.text-hover-grey:hover {
	color: #5c5c5c;
}

.text-hover-light-grey:hover {
	color: #f3f2ee;
}

.text-hover-white:hover {
	color: #dbdcda;
}

.text-hover-black:hover {
	color: #000000;
}

.text-hover-yellow:hover {
	color: #ffcf31;
}

.text-hover-orange:hover,
.text-hover-orange:focus {
	color: #e17152;
}

svg {
	overflow: visible;
	g {
		transform-origin: center center;
		path {
			transform-origin: center center;
			transform-box: fill-box;
		}
	}
}

h1,
.h1 {
	font: normal normal normal 38px/50px Whyte Inktrap;
	margin-bottom: 40px;
}

h1.banner,
.h1.banner {
	font: normal normal normal 50px/66px Whyte Inktrap;
	margin-bottom: 40px;
}

h2,
.h2 {
	font: normal normal normal 24px/38px Whyte Inktrap;
	margin-bottom: 40px;
}

h2.light,
.h2.light {
	font: normal normal 300 24px/38px Whyte Inktrap;
}

h3,
.h3 {
	font: normal normal normal 22px/28px Whyte Inktrap;
	margin-bottom: 30px;
}

h3.light,
.h3.light {
	font: normal normal 300 22px/28px Whyte Inktrap;
}

p,
.p {
	// font: normal normal normal 18px/26px Whyte Inktrap;
	font-family: acumin-pro, sans-serif;
	font-size: 18px;
	line-height: 26px;
	font-weight: 300;
	margin-bottom: 20px;
}

p.light,
.p.light {
	font-weight: 300;
}

p.small,
.p.small {
	font-size: 16px;
	line-height: 24px;
}
.pl-page {
	padding-left: 77px;
}

.pr-page {
	padding-right: 77px;
}

.pt-page {
	padding-top: 77px;
}

.pb-page {
	padding-bottom: 77px;
}

@media (min-width: 1024px) {
	.hide-desktop {
		display: none;
	}
}
@media (max-width: 1023px) {
	h1,
	.h1 {
		margin-bottom: 30px;
		font: normal normal normal 30px/40px Whyte Inktrap;
		margin-bottom: 30px;
	}

	h2,
	.h2 {
		margin-top: 20px;
		font: normal normal normal 20px/26px Whyte Inktrap;
		margin-bottom: 20px;
	}

	h2.light,
	.h2.light {
		font: normal normal 300 20px/26px Whyte Inktrap;
	}

	h3,
	.h3 {
		margin-top: 20px;
		font: normal normal normal 18px/24px Whyte Inktrap;
		margin-bottom: 20px;
	}

	p,
	.p {
		font: normal normal normal 14px/22px acumin-pro, sans-serif;
		font-weight: 400;
		margin-bottom: 20px;
	}

	p.light,
	.p.light {
		font-weight: 300;
	}

	p.small,
	.p.small {
		font-size: 16px;
		line-height: 24px;
	}

	.pl-page {
		padding-left: 20px;
	}

	.pr-page {
		padding-right: 20px;
	}

	.pt-page {
		padding-top: 50px;
	}

	.pb-page {
		padding-bottom: 50px;
	}

	.pr-md-page {
		padding-right: 20px;
	}

	.pl-md-page {
		padding-left: 20px;
	}

	.pt-md-page {
		padding-top: 50px;
	}

	.pb-md-page {
		padding-bottom: 50px;
	}

	.no-padding-left-mobile {
		padding-left: 0;
	}

	.no-padding-right-mobile {
		padding-right: 0;
	}

	.hide-mobile {
		display: none !important;
	}
}

.btn.btn-briskfil {
	min-width: 200px;
	padding: 10px 15px;
	font-size: 18px;
	position: relative;
	border: none;
	font-family: Whyte Inktrap;
	display: inline-block;
	&.outline {
		border-width: 1px;
		border-style: solid;
	}
	&.mr {
		margin-right: 30px;
	}

	&.small {
		font-size: 14px;
		font-weight: 300;
		padding: 10px 15px;
		min-width: 120px;
		&.mr {
			margin-right: 20px;
		}
	}

	&.btn-briskfil-mr {
		margin-right: 40px;
		@media (max-width: 850px) {
			display: inline-block;
			margin-bottom: 20px;
		}
	}

	.btn-briskfil-background-effect {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 100%;
		z-index: 1;
		transition: width 0.5s cubic-bezier(0.23, 1, 0.32, 1);
	}

	span {
		position: relative;
		z-index: 2;
	}

	&:hover,
	&:focus,
	&:active {
		outline: 0;
		box-shadow: none;
		.btn-briskfil-background-effect {
			width: 100%;
		}
	}
}

html,
body,
#root {
	max-width: 100vw;
	overflowx: hidden;
}

.default-cursor {
	cursor: default !important;
}

.pointer-cursor {
	cursor: pointer;
}

/* ============ ANIMATIONS OF GRAPHICS =============*/

.formas-anim-1-hover {
	padding: 15%;
	path {
		transition: 0.3s ease-out;
	}
	&:hover {
		.forma-top {
			transform: translate(0, 44%);
		}

		.forma-right {
			transform: translateX(-50%);
		}

		.forma-left {
			transform: translateX(50%);
		}

		.forma-bottom {
			transform: translate(0, -44%);
		}
	}
}

.formas-anim-2-hover {
	padding: 15%;
	path {
		transition: 0.5s ease-out;
	}

	.forma-top {
		transition: 0.3s 0.1s ease-out;
	}

	.forma-right {
		transition: 0.3s ease-out;
	}

	.forma-left {
		transition: 0.3s 0.2s ease-out;
	}

	.forma-bottom {
		transition: 0.3s 0.3s ease-out;
	}
	&:hover {
		.forma-top {
			transform: translate(-25%, 75%);
		}

		.forma-right {
			transform: translate(-25%, -75%);
		}

		.forma-left {
			transform: translate(25%, -75%);
		}

		.forma-bottom {
			transform: translate(25%, 75%);
		}
	}
}

.formas-anim-3-hover {
	padding: 15%;
	.svg_anim_3 {
		transition: 0.3s ease-out;
	}
	&:hover {
		.svg_anim_3 {
			transform: translate(-0, 12.5%);
		}
	}
}

.formas-anim-4-hover {
	padding: 15%;
	.svg_anim_4 {
		transition: 0.3s ease-out;
	}
	&:hover {
		.svg_anim_4 {
			transform: translate(-0, 12.5%);
		}
	}
}

// TOOOLTIP CSSS

.tooltip {
	pointer-events: none; /*let mouse events pass through*/
	opacity: 0;
	transition: opacity 0.3s;
	@media (max-width: 755px) {
		// opacity: 1;
		opacity: 0;
	}
}

g.tooltip:not(.css) {
	fill: currentColor;
}
g.tooltip rect {
	stroke: transparent;
}

.tooltip-wrapper {
	pointer-events: bounding-box;
}

.tooltip-wrapper:hover .tooltip {
	opacity: 0; // opacity: 1;
}

// FORCE FIXINGS FOR MOBILE

.row-custom-ilustration-margin {
	margin-bottom: -30vw;

	// @media screen and (orientation: landscape) {
	// 	margin-bottom: calc(-10vw - 200px);
	// }
	@media (max-width: 768px) {
		margin-bottom: 0;
	}
}

.custom-image-wrapper-energia-height {
	height: 25vw;
	@media (max-width: 768px) {
		height: 50vh;
	}
}
