.square-element-container {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	position: relative;

	.square-element-wrapper {
		width: 100%;

		@media (min-width: 1024px) {
			position: absolute;
			bottom: 0;
			overflow: hidden;
			height: 25vw;
		}

		.square-element-background {
			width: 50%;
			height: 100%;
			margin: 0;
			padding: 0;
			position: absolute;
			bottom: 0;
			transition: 0.5s;
			z-index: 1;
		}

		.square-element-background-image-wrapper {
			width: 50%;
			height: 100%;
			margin: 0;
			padding: 0;
			position: absolute;
			bottom: 0;
			transition: 0.5s;
			z-index: 2;
			opacity: 0;

			transition: left 0.5s, opacity 0.5s, width 0.5s;
			overflow: hidden;

			// background-color: red;

			.square-element-background-image {
				position: absolute;
				width: 50vw;
				height: 100%;
				object-fit: cover;
				object-position: center center;
				z-index: 1;
				&.background-contain {
					object-fit: contain;
					padding: 5vw;
				}
			}

			.square-element-background-image-overlay {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: #00000055;
				z-index: 2;
			}

			&.half-width {
				width: 0%;
				left: 50%;
				.square-element-background-image {
					width: 25vw;
				}
			}
		}

		.square-element-text {
			width: 50%;
			margin: 0;
			padding: 0;
			z-index: 3;

			width: 50%;
			padding: 40px;
			height: 100%;

			@media (min-width: 1024px) {
				position: absolute;
				bottom: 0;
				height: 25vw;
			}

			@media (max-width: 1023px) {
				padding: 20px 20px !important;
			}

			&.full-text {
				width: 100%;
			}

			.square-element-hover-btn {
				opacity: 0;
				transition: 0.5s ease;
			}
		}

		&.align-right {
			.square-element-background,
			.square-element-background-image-wrapper,
			.square-element-text {
				right: 0;
			}

			.square-element-background-image-wrapper {
				.square-element-background-image {
					right: 0;
				}

				&.half-width {
					position: absolute;
					width: 0;
					left: auto;
					right: 50%;
					overflow: hidden;
					.square-element-background-image {
						left: 0;
					}
				}
			}
		}

		&:hover {
			.square-element-background {
				width: 100%;
			}

			.square-element-background-image-wrapper {
				width: 100%;
				opacity: 1;
				&.half-width {
					width: 50%;

					.square-element-background-image {
						right: 0;
					}
				}
			}
			&.align-right {
				.square-element-background-image-wrapper {
					&.half-width {
						width: 50%;
					}
				}
			}
			.square-element-text {
				.square-element-hover-btn {
					opacity: 1;
				}
			}
		}
	}
}

.single-square-container {
	box-sizing: border-box;
	padding: 50px !important;
	transition: border-color 0.25s ease-in-out,
		background-color 0.25s ease-in-out;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;

	@media (max-width: 1023px) {
		&.mobile-direction-row-reverse {
			flex-direction: row-reverse;
		}
		padding: 20px 20px !important;
	}

	&.single-square-border {
		border: 1px solid #ededed;
	}

	.single-square-icon {
		// max-width: 75px;
		height: 50px;
		width: auto;
		max-height: 50px;
		margin: 30px 0;
		margin-top: 0px;
		margin-bottom: 60px;

		&.single-square-icon-big {
			display: block;
			max-width: 100px;
			max-height: 100px;
			margin: 0 auto;
			margin-top: 80px;
			margin-bottom: 30px;
			// width: ;
			// height: auto;
			// max-width: 100%;
		}

		&.single-square-icon-big-not-centered {
			max-height: 100px;
			max-width: 100px;
		}
	}

	.square-image-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		opacity: 0;
		overflow: hidden;
		transition: opacity 0.5s;
	}

	&:hover {
		.square-image-wrapper {
			opacity: 1;
		}
	}
}

.square-image-container {
	box-sizing: border-box;
	transition: border-color 0.25s ease-in-out,
		background-color 0.25s ease-in-out;

	max-height: 25vw;
	max-width: 25vw;
	&.force-center {
		display: flex;
		justify-content: center;
	}
	overflow: hidden;

	&.align-self-left {
		margin-right: auto !important;
	}

	&.mobile-col {
		max-height: 100%;
		max-width: 100%;
	}
}

.custom-image-wrapper-energia-height {
	height: 25vw;
	@media (max-width: 755px) {
		height: 50vh;
	}
}
