.donut-chart {
	position: relative;
	width: 30vh;
	height: 30vh;
	margin: 5rem 0;
	border-radius: 100%;
	border: 0;
}
.center {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	top: 15%;
	left: 15%;
	border-radius: 50%;
	width: 70%;
	height: 70%;
}

.portion-block {
	border-radius: 50%;
	clip: rect(0, 40vh, 40vh, 20vh);
	height: 100%;
	position: absolute;
	width: 100%;
}
.circle {
	border-radius: 50%;
	clip: rect(0px, 20vh, 40vh, 0px);
	height: 100%;
	position: absolute;
	width: 100%;
	font-family: monospace;
	font-size: 1.5rem;
	transition: transform 1s linear;
}

#part1 {
	transform: rotate(0deg);
}

#part1 .circle {
	// animation: first 2s 1 forwards;
}

#part2 {
	transform: rotate(calc(0.5 * 360deg));
}
#part2 .circle {
	// animation: second 2s 1 forwards 1s;
}
#part3 {
	transform: rotate(250deg);
}
#part3 .circle {
	// animation: third 0.5s 1 forwards 2s;
}

/* Animation */
@keyframes first {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(calc(0.15 * 360deg));
	}
}

@keyframes second {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(calc(0.05 * 360deg));
	}
}

@keyframes third {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(0deg);
	}
}
