.img-remove-blend {
	mix-blend-mode: multiply;
	max-width: 100%;
	max-height: 100%;
}
/* .img-remove-blend:hover {
	mix-blend-mode: normal;
} */

[data-aos^="fade-up"][data-aos^="fade-up"] {
	will-change: transform;
	-webkit-transform: translate3d(0, 20px, 0);
	transform: translate3d(0, 20px, 0);
}

/* ul {
	list-style: cir;
	padding-inline-start: 0px !important;
}

ul li::before {
	content: "\ea3c"" ";
	font-family: icomoon !important;
	width: 3ch;
	padding: 0;
} */
