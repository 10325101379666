/* Contactos */
.contact-bar-modal .contact-bar-wrapper {
	width: 0;
	pointer-events: none;
}

.contact-bar-modal .contact-bar-wrapper.active {
	width: 100vw;
	pointer-events: auto;
}

.contact-bar-modal .contact-bar-wrapper .contact-bar {
	width: 100vw;
}

@media (min-width: 1023px) {
	.contact-bar-modal .contact-bar-wrapper.active {
		width: 75vw;
	}

	.contact-bar-modal .contact-bar-wrapper .contact-bar {
		width: 75vw;
	}
}

.ContactForm {
	padding: 30px 0;
}
.formRow {
	margin-bottom: 20px;
	text-align: left;
}
input.formInput {
	background-color: transparent;
	border: none;
	border-bottom: 1px solid #707070;
	margin-top: 10px;
	margin-bottom: 10px;
	box-sizing: border-box;
	color: white;
}

input.formInput:hover,
input.formInput:focus,
input.formInput:active,
input.formInput:visited {
	background-color: transparent;
	border: none;
	border-bottom: 2px solid #e17152;
	outline: none;
	box-shadow: none;
	color: white;
}

textarea.formInput {
	background-color: transparent;
	border: none;
	border-bottom: 1px solid #707070;
	margin-bottom: 10px;
	box-sizing: border-box;
	color: white;
}

textarea.formInput:hover,
textarea.formInput:focus,
textarea.formInput:active,
textarea.formInput:visited {
	background-color: transparent;
	border: none;
	border-bottom: 2px solid #e17152;
	outline: none;
	box-shadow: none;
	color: white;
}

.submit-btn {
	border: none;
	background-color: white;
	justify-self: flex-end;
	border-radius: 10px;
	padding: 5px 20px;
	font-size: 14px;
	line-height: 24px;
}
