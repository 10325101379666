.slideshow .slick-arrow {
	width: 5vw;
	height: 5vw;
	border-radius: 100%;
	color: #fff;
}

.slideshow--custom-arrow .slick-arrow.slick-next::before {
	content: "\ea3c" !important;
}

.slideshow--custom-arrow .slick-arrow.slick-prev::after {
	padding: 0;
	margin: 0;
	display: block; /* probably not really needed? */
	content: "";
	width: 1px;
	height: 50%;
	background-color: black;
	position: absolute;
	right: 0px;
	top: 25%;
}
.slideshow--custom-arrow .slick-arrow.slick-prev::before {
	content: "\ea40" !important;
}

.slideshow--custom-arrow .slick-prev:before,
.slideshow--custom-arrow .slick-next:before {
	font-family: icomoon !important;
	font-size: 16px;
	font-weight: 200;
	line-height: 1;
	color: #000;
	opacity: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slideshow--custom-arrow .slick-arrow {
	border-radius: 0;
	top: 100%;
	left: 0;
	height: 75px;
	width: 75px;
	background-color: #e3e3e3;
	transition: background-color 0.1s ease-in-out;
}
.slideshow--custom-arrow .slick-arrow,
.slideshow--custom-arrow .slick-arrow:focus,
.slideshow--custom-arrow .slick-arrow:active,
.slideshow--custom-arrow .slick-arrow:visited {
	background-color: #e3e3e3;
}

.slideshow--custom-arrow .slick-arrow:hover,
.slideshow--custom-arrow .slick-arrow:focus:hover,
.slideshow--custom-arrow .slick-arrow:active:hover,
.slideshow--custom-arrow .slick-arrow:visited:hover {
	background-color: #efefef;
}

.slideshow--custom-arrow .slick-next {
	transform: translate(100%, calc(-75px - 5px));
	z-index: 2;
}

.slideshow--custom-arrow .slick-prev {
	transform: translate(0, calc(-75px - 5px));
	z-index: 3;
}

.slick-list {
	height: 100%;
}
.slick-track {
	display: flex !important;
	height: 100%;
}

.slick-slide {
	height: inherit !important;
	pointer-events: none;
}

.slick-slide > div {
	height: 100%;
}

.slick-slide.slick-active.slick-current {
	pointer-events: auto;
}
